<script>
import Question from "@/mixins/Question.js";
import QuestionBlock from "@/components/TestTaking/QuestionBlock.vue";
import { convertLatexToMarkup } from "mathlive";
import Select from "primevue/select";

export default {
    components: { Select, QuestionBlock },
    mixins: [Question, convertLatexToMarkup],

    data() {
        return {
            answer: {},
            answerOptions: [],
            selectRegex: new RegExp(/select-\d+/),
        };
    },

    props: ["type", "content", "options", "enableEquations"],

    computed: {
        defaultAnswer() {
            return {};
        },

        answerState() {
            return (name, bypassValueCheck = false) => {
                if (!this._graded || bypassValueCheck) {
                    return "";
                }

                const submittedAnswer = this._submittedAnswerValue(this.clientId);
                const correctAnswer = this._correctAnswer(this.clientId);

                if (correctAnswer === null || correctAnswer === undefined) {
                    return "";
                }

                if (submittedAnswer && correctAnswer && correctAnswer[name] === submittedAnswer[name]) {
                    return "correct";
                }

                return "incorrect";
            };
        },

        buildContent() {
            return this.content
                .replaceAll(
                    new RegExp(
                        `<select name="([^">]+)" [^<>]+>\\s*(?:<option[^><]*>[^><]*</option>)*\\s*</select>`,
                        "g",
                    ),
                    "---$1---",
                )
                .split("---");
        },
    },

    methods: {
        getCorrectAnswer(name) {
            if (!this._graded) {
                return "";
            }

            const correctAnswer = this._correctAnswer(this.clientId);
            if (correctAnswer === null || correctAnswer === undefined) {
                return "";
            }
            return correctAnswer[name];
        },
        renderSelectedAnswer(answerValue) {
            const key = Object.keys(this.answer).find((key) => this.answer[key] === answerValue);
            if (!key) {
                return answerValue;
            }

            if (this?.enableEquations && this.enableEquations[key]) {
                return convertLatexToMarkup(this.answer[key]);
            }
            return this.answer[key];
        },
    },

    created() {
        this.answer = { ...this.defaultAnswer };
        // Prepare answer options
        if (this.options) {
            this.answerOptions = Object.keys(this.options).reduce((acc, key) => {
                const aryOptions = [];
                this.options[key].forEach((option) => {
                    // If enableEquations is true, it is a LaTeX command
                    if (this?.enableEquations && this.enableEquations[key]) {
                        aryOptions.push({ name: convertLatexToMarkup(option), code: option });
                    } else {
                        // From our previous change, if option has LaTex command, \text{}, strip it out
                        if (option.includes("\\text{")) {
                            option = option.replace(/\\text\{([^}]+)\}/g, "$1");
                        }
                        aryOptions.push({ name: option, code: option });
                    }
                });
                acc[key] = aryOptions;

                return acc;
            }, {});
        }
    },
};
</script>

<template>
    <QuestionBlock
        :question-number="questionNumber"
        :block-id="clientId"
        @reset-answer="resetAnswer"
    >
        <p>
            <template
                v-for="segment in buildContent"
                :key="segment"
            >
                <template v-if="selectRegex.test(segment)">
                    <div class="my-1 inline-block min-w-20">
                        <Select
                            v-model="answer[segment]"
                            :options="answerOptions[segment]"
                            option-label="name"
                            option-value="code"
                            placeholder="Select an option"
                            @change="saveAnswer()"
                            :disabled="_graded"
                            class="text-base"
                        >
                            <template #value="slotProps">
                                <div
                                    v-if="slotProps.value"
                                    class="flex items-center"
                                >
                                    <!-- The selected answer is raw. It needs to be render. -->
                                    <div v-html="renderSelectedAnswer(slotProps.value)"></div>
                                </div>
                                <span v-else>
                                    {{ slotProps.placeholder }}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div v-html="slotProps.option.name" />
                            </template>
                        </Select>
                    </div>
                </template>

                <template v-else>
                    <span v-html="segment" />
                </template>
            </template>
        </p>
    </QuestionBlock>
</template>
