<script>
import Question from "@/mixins/Question.js";
import QuestionBlock from "@/components/TestTaking/QuestionBlock.vue";
import map from "lodash/map";

export default {
    components: { QuestionBlock },
    mixins: [Question],

    data() {
        return {
            answer: {},
            blankRegex: new RegExp(/blank-\d+/),
        };
    },

    props: ["type", "content"],

    computed: {
        defaultAnswer() {
            return {};
        },

        answerState() {
            return (name, bypassValueCheck = false) => {
                if (!this._graded || bypassValueCheck) {
                    return "";
                }

                const submittedAnswer = this._submittedAnswerValue(this.clientId);
                const correctAnswer = this._correctAnswer(this.clientId);

                if (correctAnswer === null || correctAnswer === undefined) {
                    return "";
                }

                if (submittedAnswer && submittedAnswer[name]) {
                    // Loop each item, then make it lower case, remove comma, and trim whitespace
                    const valCorrectAnswer = map(
                        correctAnswer[name],
                        (answer) => answer && answer.toLowerCase().replace(",", "").trim(),
                    );
                    const valSubmittedAnswer = submittedAnswer[name].toLowerCase().replace(",", "").trim();

                    let status = "incorrect";
                    // If string is type number, cast then check
                    if (!isNaN(valSubmittedAnswer)) {
                        valCorrectAnswer.forEach((answer) => {
                            if (parseInt(answer, 10) === parseInt(valSubmittedAnswer, 10)) {
                                status = "correct";
                            }
                        });
                    } else if (valCorrectAnswer.indexOf(valSubmittedAnswer) > -1) {
                        status = "correct";
                    }

                    return status;
                }

                return "incorrect";
            };
        },

        buildContent() {
            return this.content
                .replaceAll(
                    new RegExp(
                        `<input[^<>]*name="(blank-\\d+)"(?:[^<>]*)?(?:style="([^\\"]+)")(?:[^<>]*)\\/?>|<input[^<>]*name="(blank-\\d+)"\\s?(?:[^<>]*)?\\/?>`,
                        "g",
                    ),
                    "---$1$3/-//-/$2---",
                )
                .split("---")
                .map((segment) => {
                    if (segment.includes("/-//-/")) {
                        const newSegment = [...segment.split("/-//-/")];

                        return {
                            name: newSegment[0],
                            style: newSegment[1],
                        };
                    }

                    return segment;
                });
        },
    },

    methods: {
        selectAnswer(name) {
            return (event) => {
                const { value } = event.target;

                this.answer[name] = value;

                this.saveAnswer();
            };
        },

        getCorrectAnswer(name) {
            if (!this._graded) {
                return "";
            }

            const correctAnswer = this._correctAnswer(this.clientId);
            if (correctAnswer === null || correctAnswer === undefined) {
                return "";
            }

            if (typeof correctAnswer[name] === "object") {
                return correctAnswer[name].join(", ");
            }
            return correctAnswer[name];
        },
    },

    created() {
        this.answer = { ...this.defaultAnswer };
    },
};
</script>

<template>
    <QuestionBlock
        :question-number="questionNumber"
        :block-id="clientId"
        @reset-answer="resetAnswer"
        class="fill-in-the-blank"
    >
        <p v-if="!_graded">
            <template
                v-for="segment in buildContent"
                :key="segment"
            >
                <template v-if="blankRegex.test(segment?.name)">
                    <input
                        :id="`${this.clientId}-${segment?.name}`"
                        :name="segment?.name"
                        type="text"
                        class="rounded border border-gray-600 px-3"
                        :class="answerState(segment?.name)"
                        :value="answer[segment?.name]"
                        @input="
                            (event) => {
                                selectAnswer(segment?.name)(event);
                            }
                        "
                        :disabled="_graded"
                        autocomplete="off"
                        :style="segment?.style"
                    />
                </template>

                <template v-else>
                    <span v-html="segment" />
                </template>
            </template>
        </p>

        <p v-else>
            <template
                v-for="segment in buildContent"
                :key="segment"
            >
                <template v-if="blankRegex.test(segment?.name)">
                    <span
                        :class="answerState(segment?.name)"
                        class="should-be-selected"
                    >
                        {{ answer[segment?.name] }} <span class="text-sm text-gray-600">( Correct Answer: {{ getCorrectAnswer(segment?.name) }} )</span>
                    </span>
                </template>

                <template v-else>
                    <span v-html="segment" />
                </template>
            </template>
        </p>

        <!-- This can probably be removed -->
        <slot
            :selected-value="selectAnswer"
            :label-click="labelClick"
            :get-correct-answer="getCorrectAnswer"
            :answer="answer"
            :graded="_graded"
            :answer-state="answerState"
        ></slot>
    </QuestionBlock>
</template>
