<script lang="ts" setup>
import { LabelProps, useLabelConvertor } from "@/composables/useLabelConvertor";
import { computed, inject, ref } from "vue";
import { useStore } from "~/vuex";

const props = defineProps({
    ...LabelProps,
    questionClientId: {
        type: String,
        required: true,
    },
    type: {
        type: String,
        required: true,
        validator: (value: string) => ["checkbox", "radio"].includes(value),
    },
    value: {
        type: String,
        default: null,
    },
});

const store = useStore();
const graded = computed(() => store.getters.graded);

const input = ref<HTMLInputElement | null>(null);

const { getLabel, showLabel } = useLabelConvertor(props);

const { answer, selectAnswer, answerState } = inject("answer");

const label = computed(() => getLabel(props.index, true));

const labelClick = () => {
    if (graded.value) {
        return;
    }

    input.value?.click();
};
</script>

<template>
    <div
        class="answer"
        :class="answerState(value ?? label)"
        @click="labelClick"
    >
        <span
            class="answer-label"
            v-if="showLabel"
        >
            {{ label }}
        </span>

        <input
            :type="type"
            :name="`question-${questionClientId}`"
            :id="`question-${questionClientId}-${value ?? label}`"
            :value="value ?? label"
            v-model="answer"
            :disabled="graded"
            @input="selectAnswer"
            @click.stop="() => {}"
            ref="input"
        />
        <!--                {...events}-->

        <label
            class="flex-1"
            :for="`question-${questionClientId}-${value ?? label}`"
            @click.stop="() => {}"
        >
            <div style="display: inline-block; width: 100%">
                <slot />
            </div>
        </label>
    </div>
</template>
