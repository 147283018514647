import type LabelProps from "@/types/LabelProps";
import { type LabelType } from "@/types/LabelProps";
import { computed, type PropType, toValue } from "vue";

const CHAR_OFFSET_FOR_A = 64;

export function useLabelConvertor(props: LabelProps) {
    const labelType = toValue<LabelType>(props.labelType);
    const labelStart = toValue<number>(props.labelStart);
    const hideLabels = toValue<boolean>(props.hideLabels);
    const labelCustomOptions = toValue<string[] | string>(props.labelCustomOptions);

    const getLabel = (index: number, overrideHideLabel = false) => {
        // Allow the option to still get label if override is true. Otherwise, answer will be empty if hideLabels is true.
        if (labelType === "none" || (hideLabels && !overrideHideLabel)) {
            return "";
        }

        if (labelType === "custom") {
            return String(labelCustomOptions)
                .split(",")
                .map((item) => item.trim())?.[index];
        }

        if (labelType === "alphabetical") {
            let offset = +labelStart;

            if (index >= "I".charCodeAt(0) - CHAR_OFFSET_FOR_A) {
                offset += 1;
            }

            return String.fromCharCode(+index + offset + CHAR_OFFSET_FOR_A);
        }

        return +index + +labelStart;
    };

    const showLabel = computed(() => !hideLabels);

    return {
        getLabel,
        showLabel,
    };
}

export const LabelProps = {
    index: {
        type: Number,
        required: true,
    },
    labelType: {
        type: String,
        default: "radio",
    },
    labelStart: {
        type: Number,
        default: 1,
    },
    hideLabels: {
        type: Boolean,
        default: false,
    },
    labelCustomOptions: {
        type: [String, Array as PropType<string[]>],
        default: () => [],
    },
};
