<script>
import Question from "@/mixins/Question.js";
import QuestionBlock from "@/components/TestTaking/QuestionBlock.vue";

export default {
    components: { QuestionBlock },
    mixins: [Question],

    data() {
        return {
            answer: null,
        };
    },

    props: ["labelType", "content"],

    computed: {
        defaultAnswer() {
            return [];
        },

        // Return an array of content segments with the format content or [id, content, index]
        buildContent() {
            let count = 1;
            // New content format
            if (this.content.includes('data-id="ht-')) {
                return this.content
                    .replaceAll(
                        new RegExp(
                            `<ht[^<>]*data-id="ht-(\\d*)"[^<>]*>((?:\\w|\\d|\\n|[().,\\-:;@#$%^&*\\[\\]"'+–/\\/®°⁰!?“”‘’{}|\`~<>]| )+?)<\\/ht>`,
                            "g",
                        ),
                        "---$1-//-$2---",
                    )
                    .split("---")
                    .map((segment) => {
                        if (segment.includes("-//-")) {
                            const newSegment = [...segment.split("-//-"), count];

                            count += 1;

                            return newSegment;
                        }

                        return segment;
                    });
            }
            // Old content format
            return this.content
                .replaceAll(
                    new RegExp(
                        `<ht[^<>]*>((?:\\w|\\d|\\n|[().,\\-:;@#$%^&*\\[\\]"'+–/\\/®°⁰!?“”‘’{}|\`~<>]| )+?)<\\/ht>`,
                        "g",
                    ),
                    "----//-$1---",
                )
                .split("---")
                .map((segment) => {
                    if (segment.includes("-//-")) {
                        const newSegment = [count, ...segment.split("-//-").filter((value) => value), count];

                        count += 1;

                        return newSegment;
                    }

                    return segment;
                });
        },
    },

    methods: {
        selectAnswer(event) {
            const { checked } = event.target;
            const { value } = event.target;

            if (checked) {
                this.answer.push(value);
            } else {
                const index = this.answer.indexOf(value);

                if (index > -1) {
                    this.answer.splice(index, 1);
                }
            }

            this.saveAnswer();
        },

        getLabel(index) {
            if (this.labelType === "numerical") return index;
            return String.fromCharCode(65 + index - 1);
        },
    },

    created() {
        let { defaultAnswer } = this;

        if (Array.isArray(defaultAnswer)) {
            defaultAnswer = [...defaultAnswer];
        }

        this.answer = defaultAnswer;
    },
};
</script>

<template>
    <QuestionBlock
        :question-number="questionNumber"
        :block-id="clientId"
        @reset-answer="resetAnswer"
        class="hot-text-block"
    >
        <p>
            <template
                v-for="segment in buildContent"
                :key="segment"
            >
                <template v-if="Array.isArray(segment)">
                    <label
                        :for="`ht-${clientId}-${segment[0]}`"
                        class="hot-text"
                        :class="[answer?.indexOf(`${segment[2]}`) > -1 ? 'selected' : '', answerState(`${segment[2]}`)]"
                    >
                        <span class="option-label">{{ getLabel(segment[2]) }}</span>

                        <input
                            :id="`ht-${clientId}-${segment[0]}`"
                            type="checkbox"
                            class="hidden"
                            :value="`${segment[2]}`"
                            :checked="answer?.indexOf(`${segment[2]}`) > -1"
                            @input="
                                (event) => {
                                    selectAnswer(event, `${segment[2]}`);
                                }
                            "
                            :disabled="_graded"
                        />

                        {{ segment[1] }}
                    </label>
                </template>

                <template v-else>
                    <span v-html="segment" />
                </template>
            </template>
        </p>

        <!-- This can probably be removed -->
        <slot
            :selected-value="selectedValue"
            :label-click="labelClick"
            :answer="answer"
            :graded="_graded"
            :answer-state="answerState"
        ></slot>
    </QuestionBlock>
</template>
