<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import { useBroadcastChannel } from "@vueuse/core";
import axios from "axios";

// Rename component to TestReview to satisfy multi-word component name rule
defineOptions({
    name: "TestReview",
});

const store = useStore();
const processing = ref(false);
const errorMsg = ref("");

// Setup broadcast channel
const { isSupported, post } = useBroadcastChannel({ name: "test_submitted_channel" });

const broadcastMessage = (message) => {
    if (!isSupported.value) {
        return;
    }
    post(message);
};

// Computed properties
const hasUnansweredQuestions = computed(() => store.getters.hasUnansweredQuestions);
const unansweredQuestions = computed(() => store.getters.unansweredQuestions);
const isSidebarOpen = computed(() => store.getters.isSidebarOpen);
const submission = computed(() => store.getters.submission);
const testId = computed(() => store.getters.testId);
const testSubmitted = computed(() => store.getters.testSubmitted);

const classNames = computed(() => ({
    "text-primary-darker": !hasUnansweredQuestions.value,
    "text-warning": hasUnansweredQuestions.value,
}));

const heading = computed(() =>
    hasUnansweredQuestions.value ? `${unansweredQuestions.value} Unanswered questions` : "All questions answered",
);

const iconName = computed(() => (hasUnansweredQuestions.value ? "exclamation-circle" : "check-circle"));

// Methods
const submitTest = async () => {
    processing.value = true;
    errorMsg.value = "";

    try {
        let response;
        if (!testSubmitted.value) {
            response = await axios.post(route("test.submission.submit", { test: testId.value }), {
                submission_id: submission.value.id,
            });
            store.commit("updateSubmission", response.data.submission);
        }

        // Broadcast message to other tabs
        broadcastMessage("Test submitted!");
    } catch (error) {
        console.log(error.response);
        if (error.response?.data?.message) {
            errorMsg.value = error.response.data.message;
        }
    } finally {
        processing.value = false;
    }
};

// Mounted logic
store.dispatch("createSubmission"); // Refresh submission model
if (hasUnansweredQuestions.value && !isSidebarOpen.value) {
    store.commit("toggleSidebar");
}
</script>

<template>
    <div class="mx-auto flex w-full max-w-2xl flex-col items-start justify-center">
        <div class="flex h-40 w-full items-center justify-center rounded-lg border-2 border-primary-lighter text-2xl">
            {{ heading }}

            <FontAwesomeIcon
                :icon="iconName"
                size="lg"
                class="ml-2"
                :class="classNames"
            ></FontAwesomeIcon>
        </div>

        <div v-if="!testSubmitted">
            <div
                class="w-full"
                v-if="!hasUnansweredQuestions"
            >
                <p class="mt-3">
                    Before you submit your test, use the question list in the upper left corner to review your answers
                    to ensure they are correct.
                </p>

                <p class="mt-3">
                    Once you complete your review, click the Submit final answers button below to submit your test.
                </p>
            </div>

            <div
                class="w-full"
                v-else
            >
                <p class="mt-3">
                    Use the question list in the upper left corner to review all questions and provide answers for any
                    unanswered questions.
                </p>

                <p class="mt-3">
                    Once you complete all answers, click the Submit final answers button below to submit your test.
                </p>

                <p class="mt-3">Any questions you leave unanswered will be marked incorrect.</p>
            </div>

            <div class="mt-10 flex w-full justify-center">
                <BtnPrimary
                    @click="submitTest"
                    :disabled="processing"
                >
                    Submit final answers
                </BtnPrimary>
            </div>

            <div
                v-if="errorMsg"
                v-html="errorMsg"
                class="mt-4 text-center text-red-700"
            ></div>
        </div>

        <div
            v-else
            class="mt-10 w-full text-center text-xl"
        >
            Your test has been submitted! Thank you.
            <div class="mt-8">
                <BtnPrimary
                    class=""
                    :href="route('home')"
                >
                    Return to Test List
                    <FontAwesomeIcon
                        icon="chevron-right"
                        class="ml-2"
                    />
                </BtnPrimary>
            </div>

            <div class="mt-2 w-full text-center">
                <a
                    :href="route('student-logout-get')"
                    class="text-lg text-red-500 hover:text-red-700"
                    >or log out now</a
                >
            </div>
        </div>
    </div>
</template>
