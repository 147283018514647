import initializeSentry from "@/initializeSentry";
import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";

import * as Sentry from "@sentry/vue";

import "./bootstrap";
import "../sass/app.scss";
import "../sass/gutenberg.scss";
import "../sass/tinymce.scss";
import "pdfjs-dist/web/pdf_viewer.css";

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
// import "./bootstrap";
// FA Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleDown,
    faArrowLeft,
    faArrowRight,
    faBars,
    faBook,
    faCaretSquareLeft,
    faCheck,
    faCheckCircle,
    faChevronLeft,
    faCopy,
    faEdit,
    faEnvelope,
    faExclamationCircle,
    faEye,
    faPencilAlt,
    faPlus,
    faRedoAlt,
    faSort,
    faSpinner,
    faSync,
    faThumbsUp,
    faTimes,
    faTimesCircle,
    faTrash,
    faUserMinus,
    faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import router from "@/router";
import store from "@/store";

import { ZiggyVue } from "ziggy-js";
import { resolvePageComponent } from "~/laravel-vite-plugin/inertia-helpers";

import PrimeVue from "primevue/config";
import { definePreset } from "@primeuix/themes";
import Aura from "@primeuix/themes/aura";
import PrimeVueThemeCustomizations from "@/PrimeVueThemeCustomizations";

const customTheme = definePreset(Aura, PrimeVueThemeCustomizations);

/* add icons to the library */
library.add(
    faPlus,
    faAngleDown,
    faSync,
    faChevronLeft,
    faEdit,
    faEye,
    faTrash,
    faBook,
    faCopy,
    faPencilAlt,
    faTimes,
    faCaretSquareLeft,
    faBars,
    faSpinner,
    faArrowRight,
    faArrowLeft,
    faRedoAlt,
    faEnvelope,
    faTimesCircle,
    faCheckCircle,
    faExclamationCircle,
    faThumbsUp,
    faCheck,
    faSort,
    faUserPlus,
    faUserMinus,
);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

createInertiaApp({
    resolve: (name) => {
        const page = resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue"));
        return page;
    },

    // default Inertia resolve with dynamic imports of pages
    // const pages = import.meta.glob('./Pages/**/*.vue')
    //  return  <Promise<DefineComponent>>pages[`./Pages/${name}.vue`]();
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(router)
            .use(store)
            .use(PrimeVue, {
                theme: {
                    preset: customTheme,
                    options: {
                        darkModeSelector: ".dark",
                        cssLayer: {
                            name: "primevue",
                            order: "tailwind-base, primevue",
                        },
                    },
                },
            })
            .component("FontAwesomeIcon", FontAwesomeIcon)
            .component("FontAwesomeLayers", FontAwesomeLayers);

        initializeSentry(app, {
            integrations: [Sentry.browserTracingIntegration({ router })],
        });

        // add window to vue for accessing window.route for Ziggy
        app.config.globalProperties.window = window;
        // Wait until router is ready to mount the app.
        // Otherwise, the initial page load will not have access to the $route query.
        router.isReady().then(() => {
            app.mount(el);
        });

        return app;
    },
});
