<script lang="ts" setup>
import BtnPrimary from "@/components/Global/BtnPrimary.vue";
import { useBroadcastChannel } from "@vueuse/core";
import ContentRender from "@/components/TestTaking/ContentRender.vue";
import { computed, onMounted, watch } from "vue";
import { useStore } from "~/vuex";

const store = useStore();
const { isSupported, data } = useBroadcastChannel({ name: "preview_channel" });
const isBCSupported = isSupported;
const channelData = data;

const getNextPage = (increment: number) => store.getters.getNextPage(increment);
// Computed properties
const isStudent = computed(() => {
    if (store.state.user?.code || store.state.user?.role === "student") {
        return true;
    }
    return false;
});

const graded = computed(() => store.getters.graded);
const isLastContentPage = computed(() => store.getters.isLastContentPage);
const isPreview = computed(() => store.getters.isPreview);
const isSample = computed(() => store.getters.isSample);
const navigating = computed(() => store.getters.navigating);
const numberOfPages = computed(() => store.getters.numberOfPages);
const page = computed(() => store.getters.page);
const pageNumber = computed(() => store.getters.pageNumber);
const saving = computed(() => store.getters.saving);
const testSubmitted = computed(() => store.getters.testSubmitted);

const buttonText = computed(() => {
    if (saving.value) {
        return "Saving";
    }

    return !isLastContentPage.value ? "Go to next page" : "Review my test";
});

const disableButtons = computed(() => saving.value || navigating.value);
const hasNextPage = computed(() => getNextPage(1));
const displayButton = computed(
    () =>
        hasNextPage.value &&
        (!isLastContentPage.value || (isLastContentPage.value && !isSample.value && !isPreview.value && !graded.value)),
);

const needsTopPadding = computed(() => {
    const name = _.get(_.last(page.value.content), "name");
    return !!(name !== undefined && name.indexOf("questions") === -1);
});

// Methods
const changeSaving = (value) => {
    store.getters.saving = value;
};

const scrollTestPageToTop = () => {
    const pageContainerElement = document.getElementById("test-page-container");
    if (!pageContainerElement) {
        return;
    }
    pageContainerElement.scrollIntoView({ behavior: "smooth" });
};

const goToPage = () => {
    if (navigating.value || disableButtons.value) {
        return;
    }

    store.dispatch("updateLastCompletedPage", pageNumber.value);
    if (pageNumber.value < numberOfPages.value) {
        store.dispatch("navigateToPage", getNextPage(1)).then(() => {
            scrollTestPageToTop();
        });
    } else {
        store.dispatch("goToReview");
    }
};

onMounted(() => {
    // If it's not preview and test is submitted, redirect to resource page to prevent students from trying to change answer
    if (isStudent.value && !isPreview.value && testSubmitted.value) {
        // Redirect to resource page... student-logout-get
        window.location.href = route("student-logout-get");
        return;
    }

    // If Broadcast Channels are supported
    if (isBCSupported) {
        watch(channelData, (value) => {
            // When broadcast channel receives a message, reload the page
            window.location.reload();
        });
    }
});
</script>

<template>
    <div
        class="test-page wp-embed-responsive mx-auto w-full max-w-1180p flex-1 pb-50p"
        id="test-page"
    >
        <div
            v-for="block in page.content"
            :key="block.clientId"
        >
            <ContentRender
                :block="block"
                @change-saving="changeSaving"
            />
        </div>

        <div
            class="flex h-100p w-full flex-row-reverse"
            :class="{ 'mt-5': needsTopPadding }"
        >
            <div>
                <BtnPrimary
                    arrow
                    arrow-class="ml-4"
                    class="h-75p"
                    :disabled="disableButtons"
                    @click="goToPage"
                    v-if="displayButton"
                >
                    <FontAwesomeIcon
                        icon="redo-alt"
                        :spin="saving"
                        flip="horizontal"
                        size="lg"
                        class="mx-3"
                        v-if="saving"
                    />
                    {{ buttonText }}
                </BtnPrimary>
            </div>
        </div>
    </div>
</template>
